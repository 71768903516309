import api from '@/services/api'

export default {
  getHirings(page, params) {
    let query = '';
    
    if (!params.export) {
      query = `?page=${page}`;
    }

    if (params.export) {
      query = '?export=true';
    }

    if (params.hirer) {
      query += `&hirer=${params.hirer}`;
    }

    if (params.provider) {
      query += `&provider=${params.provider}`;
    }

    if (params.service) {
      query += `&service=${params.service}`;
    }

    if (params.startDate) {
      query += `&startDate=${params.startDate}`;
    }

    if (params.endDate) {
      query += `&endDate=${params.endDate}`;
    }

    if (params.startServiceDate) {
      query += `&startServiceDate=${params.startServiceDate}`;
    }

    if (params.endServiceDate) {
      query += `&endServiceDate=${params.endServiceDate}`;
    }

    if (params.status) {
      query += `&status=${params.status}`;
    }

    if (params.paymentStatus) {
      query += `&paymentStatus=${params.paymentStatus}`;
    }

    if (params.paymentMethod) {
      query += `&paymentMethod=${params.paymentMethod}`;
    }

    if (params.code) {
      query += `&code=${params.code}`;
    }

    if (params.export) {
      return api({
        url: `/hirings/admin${query}`,
        method: 'GET',
        responseType: 'blob',
      })
    }

    return api.get(`/hirings/admin${query}`)
  },

  resendReceipt(hiringId) {
    return api.post(`/hirings/send-receipt/${hiringId}`)
  },

  downloadReceipt(hiringId) {
    return api({
      url: `/hirings/download-receipt/${hiringId}`,
      method: 'GET',
      responseType: 'blob',
    })
  },

  estimateHiring(data) {
    return api.post('/hirings/estimate', data)
  },

  createNewHiring(data) {
    return api.post('/hirings/create', data)
  },

  changeStatus(id, status) {
    return api.post('/hirings/change-status/' + id, { status })
  },
}