<script>
import moment from 'moment';
moment.locale('pt-BR');

import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import HiringService from '@/services/hiring';
import UserService from '@/services/user';
import Multiselect from 'vue-multiselect';
import DatePicker from "vue2-datepicker";
import 'vue2-datepicker/locale/pt-br';
import { VMoney } from 'v-money'

export default {
  page: {
    title: "Serviços",
  },
  components: { Layout, PageHeader, Multiselect, DatePicker },
  directives: { money: VMoney },
  data() {
    return {
      title: "Serviços",
      hirings: [],
      hiringSelected: {
        hirer: {},
        checkins: [],
      },
      newHiringData: {
        service: {},
        value: 0,
        manualValue: 0,
        useManualValue: false,
      },
      newHiringError: '',
      newStatus: '',
      serviceTypes: [],

      count: 0,
      currentCount: 0,
      currentPage: 1,
      totalPages: 0,
      perPage: 0,
      startIndex: 0,
      endIndex: 0,

      searchProvider: '',
      searchHirer: '',
      searchService: '',
      searchDate: '',
      searchServiceDate: '',
      searchStatus: '',
      searchPaymentStatus: '',
      searchPaymentMethod: '',
      searchCode: '',

      hirers: [],
      providers: [],
      services: [],
      status: [],
      paymentStatus: [],
      paymentMethods: [],

      money: {
        decimal: ',',
        thousands: '.',
        prefix: '',
        suffix: '',
        precision: 2,
        masked: false,
      }
    }
  },
  computed: {
    rows() {
      return this.hirings.length;
    }
  },
  methods: {
    loadHirings(page = 1, hideLoading = false) {
      let loader;

      if (!hideLoading) {
        loader = this.$loading.show();
      }

      const params = this.getSearchParams();

      HiringService.getHirings(page, params).then(res => {
        this.hirings = res.data.hirings;

        this.count = res.data.count
        this.currentCount = res.data.currentCount
        this.currentPage = res.data.currentPage
        this.totalPages = res.data.totalPages
        this.perPage = res.data.perPage
        this.startIndex = res.data.startIndex
        this.endIndex = res.data.endIndex

        if (!hideLoading) {
          loader.hide();
        }
      }).catch(() => {
        if (!hideLoading) {
          loader.hide();
        }
      })
    },
    onPageChange() {
      this.loadHirings(this.currentPage);
    },
    confirmResendReceipt(hiring) {
      this.hiringSelected = hiring;
      this.$bvModal.show('modal-resend-receipt');    
    },
    downloadReceipt(hiring) {
      HiringService.downloadReceipt(hiring._id).then(res => {
        var fileURL = window.URL.createObjectURL(new Blob([res.data]));
        var fileLink = document.createElement('a');

        fileLink.href = fileURL;
        fileLink.setAttribute('download', `recibo-${hiring.code}.pdf`);
        document.body.appendChild(fileLink);

        fileLink.click();
      }).catch(() => {
        this.$toast.error('Não foi possível fazer o download do recibo');
      })
    },
    resendReceipt() {
      this.$bvModal.hide('modal-resend-receipt');  

      const loader = this.$loading.show();

      HiringService.resendReceipt(this.hiringSelected._id).then(() => {
        loader.hide();

        this.$toast.open('Recibo reenviado ao contratante');
      }).catch(() => {
        loader.hide();
      })
    },
    showCheckins(hiring) {
      this.hiringSelected = hiring;
      this.$bvModal.show('modal-checkins');    
    },
    search() {
      this.loadHirings();
    },
    clearFilters() {
      this.searchHirer = '';
      this.searchProvider = '';
      this.searchService = '';
      this.searchDate = '';
      this.searchServiceDate = '';
      this.searchStatus = '';
      this.searchCode = '';
      this.searchPaymentStatus = '';
      this.searchPaymentMethod = '';
    },
    exportData() {
      const loader = this.$loading.show();

      const params = this.getSearchParams();
      params.export = true;

      HiringService.getHirings(null, params).then(res => {
        loader.hide();

        const fileURL = window.URL.createObjectURL(new Blob([res.data]));
        let fileLink = document.createElement('a');

        fileLink.href = fileURL;
        fileLink.setAttribute('download', 'lista-contratacoes.csv');
        document.body.appendChild(fileLink);

        fileLink.click();
      }).catch(() => {
        loader.hide();
      })
    },
    getSearchParams() {
      const params = {};

      if (this.searchHirer) {
        params.hirer = this.searchHirer._id;
      }

      if (this.searchProvider) {
        params.provider = this.searchProvider._id;
      }

      if (this.searchService) {
        params.service = this.searchService._id;
      }

      if (this.searchDate && this.searchDate[0]) {
        params.startDate = this.searchDate[0];
        params.endDate = this.searchDate[1];
      }

      if (this.searchServiceDate && this.searchServiceDate[0]) {
        params.startServiceDate = this.searchServiceDate[0];
        params.endServiceDate = this.searchServiceDate[1];
      }

      if (this.searchStatus) {
        params.status = this.searchStatus.status;
      }

      if (this.searchCode) {
        params.code = this.searchCode;
      }

      if (this.searchPaymentStatus) {
        params.paymentStatus = this.searchPaymentStatus.status;
      }

      if (this.searchPaymentMethod) {
        params.paymentMethod = this.searchPaymentMethod.method;
      }

      return params;
    },
    newHiring() {
      this.newHiringData = {
        service: {
          dailyValue: 0
        },
        holidaysCount: 0,
        value: 0,
      };

      this.$bvModal.show('modal-new-hiring');
    },
    confirmNewHiring() {
      this.newHiringError = '';

      const loader = this.$loading.show();

      HiringService.createNewHiring({
        ...this.newHiringData,
        manualValue: (this.newHiringData.useManualValue && this.newHiringData.manualValue) ? document.getElementById('manualValue').value : 0,
      }).then(() => {
        loader.hide();

        this.$toast.open('Contratação criada com sucesso');
        this.loadHirings(1, true);
        this.$bvModal.hide('modal-new-hiring');  
      }).catch(err => {
        loader.hide();

        const errorData = err.response.data;
        if (errorData.code) {
          if (errorData.code === 'E9999') {
            return this.newHiringError = errorData.msg;
          }
        }

        this.newHiringError = 'Ocorreu um erro ao salvar a contratação';
      })
    },
    changeServiceTypes() {
      if (this.newHiringData.provider) {
        this.serviceTypes = this.newHiringData.provider.services.map(item => {
          return {
            ...item,
            serviceId: item.serviceType._id,
            serviceDescription: item.serviceType.description,
          }
        });

        this.newHiringData.service = {
          dailyValue: 0,
        }

        if (this.serviceTypes.length > 0) {
          this.newHiringData.serviceType = this.serviceTypes[0].serviceId;
        }

        this.changeServiceType();
      }
    },
    changeServiceType() {
      if (this.newHiringData.serviceType) {
        const service = this.serviceTypes.find(item => {
          return item.serviceId === this.newHiringData.serviceType;
        });

        if (service) {
          this.newHiringData.service = service;
          this.estimateHiring();
        }
      }
    },
    estimateHiring() {
      this.newHiringError = '';

      HiringService.estimateHiring(this.newHiringData).then(res => {
        this.newHiringData.value = res.data.value;
        this.newHiringData.quantity = res.data.quantity;

        this.$forceUpdate();
      }).catch(err => {
        const errorData = err.response.data;
        if (errorData.code) {
          if (errorData.code === 'E9999') {
            return this.newHiringError = errorData.msg;
          }
        }

        this.newHiringError = 'Ocorreu um erro ao calcular a contratação';
      })
    },
    changeManualValue() {
      if (!this.newHiringData.useManualValue) {
        this.newHiringData.useManualValue = true;
        this.newHiringData.manualValue = (this.newHiringData.value || 0).toFixed(2).replace('.', ',');
      } else {
        this.newHiringData.useManualValue = false;
        this.newHiringData.manualValue = 0;
      }

      this.$forceUpdate();
    },
    editHiring(hiring) {
      this.newHiringData = hiring;
      this.newHiringData.editing = true;

      this.$bvModal.show('modal-new-hiring');
    },
    changeHiringStatusModal(hiring) {
      this.hiringSelected = hiring;
      this.$bvModal.show('modal-change-status');
    },
    changeHiringStatus() {
      const loader = this.$loading.show();

      HiringService.changeStatus(this.hiringSelected._id, this.newStatus).then(() => {
        loader.hide();

        this.$toast.open('Status alterado');
        this.loadHirings(1, true);
        this.$bvModal.hide('modal-change-status');  
      }).catch(() => {
        loader.hide();

        this.$toast.error('Não foi possível alterar o status da contratação');
      })
    }
  },
  mounted() {
    this.loadHirings();

    UserService.getServices().then(res => {
      this.services = res.data;
    })

    UserService.getProviders(1, { all: true }).then(res => {
      this.providers = res.data;
    })

    UserService.getHirers(1, { all: true }).then(res => {
      this.hirers = res.data;
    })

    this.status = [
      { status: 'waitingAcceptance', description: 'Aguardando Aprovação' },
      { status: 'accepted', description: 'Confirmado' },
      { status: 'inProgress', description: 'Em Execução' },
      { status: 'finish', description: 'Finalizado' },
      { status: 'canceled', description: 'Cancelado' },
      { status: 'rejected', description: 'Rejeitado' },
      { status: 'noshow', description: 'No Show' },
      { status: 'expired', description: 'Expirado' },
    ];

    this.paymentStatus = [
      { status: 'paymentApproved', description: 'Pago' },
      { status: 'waitingPayment', description: 'Pendente' },
      { status: 'paymentRejected', description: 'Rejeitado' },
    ];

    this.paymentMethods = [
      { method: 'online', description: 'Cartão Online' },
      { method: 'bankSlip', description: 'Boleto Bancário' },
    ];
  }
}
</script>

<template>
  <Layout>
    <PageHeader :title="title"/>
    <div class="row">
      <div class="col">
        <div class="card mb-0">
          <div class="card-body">
            <form @submit.prevent="search" class="align-items-center direction-column">
              <div class="row">
                <div class="col-4">
                  <label class="mb-1">Oficina</label>
                  <multiselect
                    v-model="searchHirer"
                    :options="hirers"
                    label="name"
                    track-by="_id"
                    placeholder="Pesquise pelo nome"
                    selectLabel=""
                    selectedLabel=""
                    deselectLabel=""
                    :close-on-select="true"
                    :searchable="true"
                  ></multiselect>
                </div>
                <div class="col-4 mb-1">
                  <label class="mb-1">Prestador</label>
                  <multiselect
                    v-model="searchProvider"
                    :options="providers"
                    label="name"
                    track-by="_id"
                    placeholder="Pesquise pelo nome"
                    selectLabel=""
                    selectedLabel=""
                    deselectLabel=""
                    :close-on-select="true"
                    :searchable="true"
                  ></multiselect>
                </div>
                <div class="col-4 search-users">
                  <button type="submit" class="btn btn-rounded btn-primary button-large">
                    Pesquisar
                  </button>

                  <button class="btn btn-rounded btn-outline-primary ml-2" @click="exportData">
                    Exportar CSV
                  </button>
                </div>
              </div>

              <div class="row">
                <div class="col-8">
                  <div class="row mt-3">
                    <div class="col col-4">
                      <label class="mb-1">Serviço</label>
                      <multiselect
                        v-model="searchService"
                        :options="services"
                        label="description"
                        track-by="_id"
                        placeholder=""
                        selectLabel=""
                        selectedLabel=""
                        deselectLabel=""
                      ></multiselect>
                    </div>

                    <div class="col col-4">
                      <label class="mb-1">Data da Contratação</label>
                      <date-picker
                        v-model="searchDate"
                        :first-day-of-week="1"
                        range
                        format="DD/MM/YYYY"
                        value-type="format"
                      ></date-picker>
                    </div>

                    <div class="col col-4">
                      <label class="mb-1">Data do Serviço</label>
                      <date-picker
                        v-model="searchServiceDate"
                        :first-day-of-week="1"
                        range
                        format="DD/MM/YYYY"
                        value-type="format"
                      ></date-picker>
                    </div>
                  </div>

                  <div class="row mt-3">
                    <div class="col col-2">
                      <label class="mb-1">Código</label>
                      <input type="text" class="form-control" v-model="searchCode" />
                    </div>

                    <div class="col col-4">
                      <label class="mb-1">Status</label>
                      <multiselect
                        v-model="searchStatus"
                        :options="status"
                        label="description"
                        track-by="status"
                        placeholder=""
                        selectLabel=""
                        selectedLabel=""
                        deselectLabel=""
                      ></multiselect>
                    </div>

                    <div class="col col-3">
                      <label class="mb-1">Status Pagamento</label>
                      <multiselect
                        v-model="searchPaymentStatus"
                        :options="paymentStatus"
                        label="description"
                        track-by="status"
                        placeholder=""
                        selectLabel=""
                        selectedLabel=""
                        deselectLabel=""
                      ></multiselect>
                    </div>

                    <div class="col col-3">
                      <label class="mb-1">Forma de Pagamento</label>
                      <multiselect
                        v-model="searchPaymentMethod"
                        :options="paymentMethods"
                        label="description"
                        track-by="method"
                        placeholder=""
                        selectLabel=""
                        selectedLabel=""
                        deselectLabel=""
                      ></multiselect>
                    </div>
                  </div>
                </div>
              </div>
            </form>

            <div class="row mt-3 mb-0 bottom-buttons">
              <b-button variant="link" @click="clearFilters" class="mr-2">Limpar Filtros</b-button>

              <button class="btn btn-rounded btn-outline-success ml-2" @click="newHiring">
                Nova Contratação
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div class>
          <div class="table-responsive">
            <table class="table project-list-table table-centered table-borderless">
              <thead>
                <tr>
                  <th scope="col">#</th>                  
                  <th scope="col">Oficina</th>
                  <th scope="col">Profissional</th>
                  <th scope="col">Status</th>
                  <th scope="col">Status Pagamento</th>
                  <th scope="col">Valor Total</th>
                  <th scope="col">Forma de Pagamento</th>
                  <th scope="col">Serviço</th>
                  <th scope="col">Data Contratação</th>
                  <th scope="col">Período Contratado</th>
                  <th scope="col">Ações</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="hiring in hirings" :key="hiring._id">
                  <td>{{hiring.code}}</td>                  
                  <td>
                    <div class="team">
                      <!-- <a href="javascript: void(0);" class="team-member d-inline-block" v-if="hiring.hirer.picture">
                        <img :src="hiring.hirer.picture" class="rounded-circle avatar-xs m-1" alt />
                      </a> -->
                      <!-- <div class="rounded-circle avatar-xs m-1 avatar-letter" v-if="!hiring.hirer.picture">{{hiring.hirer.name.toUpperCase().substring(0,1)}}</div> -->
                      <div class="ml-1">
                        {{hiring.hirer.name}}
                        <p class="text-muted font-size-11 m-0">{{hiring.hirer.cnpj}}</p>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="team">
                      <!-- <a href="javascript: void(0);" class="team-member d-inline-block" v-if="hiring.provider.picture">
                        <img :src="hiring.provider.picture" class="rounded-circle avatar-xs m-1" alt />
                      </a> -->
                      <!-- <div class="rounded-circle avatar-xs m-1 avatar-letter" v-if="!hiring.provider.picture">{{hiring.provider.name.toUpperCase().substring(0,1)}}</div> -->
                      <div class="ml-1">
                        {{hiring.provider.name}}
                        <p class="text-muted font-size-11 m-0">{{hiring.provider.cpf}}</p>
                      </div>
                    </div>
                  </td>
                  <td>
                    <span class="badge badge-pill font-size-11" :class="{
                      'badge-warning': `${hiring.status}` === 'paymentApproved' || `${hiring.status}` === 'waitingAcceptance',
                      'badge-info': `${hiring.status}` === 'inProgress' || `${hiring.status}` === 'accepted',
                      'badge-success': `${hiring.status}` === 'inProgress',
                      'badge-soft-secondary': `${hiring.status}` === 'finish',
                      'badge-dark': `${hiring.status}` === 'noshow',
                      'badge-soft-danger': `${hiring.status}` === 'canceled' || `${hiring.status}` === 'paymentRejected' || `${hiring.status}` === 'rejected' || `${hiring.status}` === 'expired',
                    }"
                    >{{hiring.statusDescription}}</span>
                  </td>
                  <td>
                    <span class="badge badge-pill font-size-11" :class="{
                      'badge-soft-success': `${hiring.paymentStatus}` === 'paymentApproved',
                      'badge-soft-warning': `${hiring.paymentStatus}` === 'waitingPayment',
                      'badge-soft-danger': `${hiring.paymentStatus}` === 'paymentRejected'
                    }"
                    >{{hiring.paymentStatusDescription}}</span>
                  </td>
                  <td>{{hiring.value | currency}}</td>
                  <td>{{hiring.payment.method === 'bankSlip' ? 'Boleto Bancário' : 'Cartão Online'}}</td>
                  <td>{{hiring.service}}</td>
                  <td>{{new Date(hiring.date) | dateFormat('DD/MM/YYYY')}}</td>
                  <td>
                    <p class="mt-0 mb-0">{{hiring.period}}</p>
                    <p class="text-muted font-size-12 m-0">{{hiring.dailyQuantity}}</p>
                  </td>
                  <td>
                    <b-dropdown class="card-drop" variant="white" right toggle-class="p-0">
                      <template v-slot:button-content>
                        <i class="mdi mdi-dots-horizontal font-size-18"></i>
                      </template>
                      <!-- <b-dropdown-item href="javascript: void(0);">Cancelar Serviço</b-dropdown-item>
                      <b-dropdown-item href="javascript: void(0);">Emitir NF</b-dropdown-item> -->
                      <b-dropdown-item @click="changeHiringStatusModal(hiring)" href="javascript: void(0);" :disabled="hiring.status === 'canceled'">Alterar Status</b-dropdown-item>
                      <!-- <b-dropdown-item @click="editHiring(hiring)" href="javascript: void(0);">Alterar Contratação</b-dropdown-item> -->
                      <b-dropdown-item @click="showCheckins(hiring)" href="javascript: void(0);">Ver Apontamentos</b-dropdown-item>
                      <b-dropdown-item @click="downloadReceipt(hiring)" href="javascript: void(0);">Baixar Recibo</b-dropdown-item>
                      <b-dropdown-item @click="confirmResendReceipt(hiring)" href="javascript: void(0);">Reenviar Recibo</b-dropdown-item>
                    </b-dropdown>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div class="row justify-content-md-between align-items-md-center">
      <div class="col-xl-7">Mostrando {{startIndex}} - {{endIndex}} de {{count}}</div>

      <div class="col-xl-5">
        <div class="text-md-right float-xl-right mt-2 pagination-rounded">
          <b-pagination
            v-model="currentPage" 
            :total-rows="count"
            :per-page="perPage"
            @input="onPageChange"
          ></b-pagination>
        </div>
      </div>
    </div>

    <b-modal
      id="modal-resend-receipt"
      title="Confirmação"
      title-class="font-18"
      centered
    >
      <p>Confirma o envio do recibo da contratação <b>{{hiringSelected.code}}</b> para o e-mail <b>{{hiringSelected.hirer.email}}</b>?</p>

      <div slot="modal-footer">
        <b-btn variant="link" @click="$bvModal.hide('modal-resend-receipt')" class="mr-3">Cancelar</b-btn>
        <b-btn variant="primary" @click="resendReceipt">Reenviar</b-btn>
      </div>
    </b-modal>

    <b-modal
      id="modal-checkins"
      :title="'Serviço #' + hiringSelected.code"
      title-class="font-18"
      centered
    >
      <p v-if="hiringSelected.checkins.length === 0">Essa contratação ainda não possui nenhum apontamento.</p>

      <div class="table-responsive" v-if="hiringSelected.checkins.length > 0">
        <table class="table">
          <thead>
            <tr class="border-0">
              <th class="border-0">Etapa</th>
              <th class="border-0">Data</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="checkin in hiringSelected.checkins" :key="checkin._id" class="font-size-12">
              <td>{{checkin.step}}</td>
              <td>{{new Date(checkin.date) | dateFormat('DD/MM/YYYY HH:mm:ss')}}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div slot="modal-footer">
        <b-btn variant="link" @click="$bvModal.hide('modal-checkins')" class="mr-3">Fechar</b-btn>
      </div>
    </b-modal>

    <b-modal
      id="modal-new-hiring"
      title="Nova Contratação"
      title-class="font-18"
      centered
    >
      <b-alert show dismissible v-if="newHiringError" variant="danger">{{newHiringError}}</b-alert>

      <div class="row">
        <div class="col-5">
          <div class="form-group mb-3">
            <label>Data de Início</label>
            <br />
            <date-picker v-model="newHiringData.startDate" :first-day-of-week="1" lang="pt-br" format="DD/MM/YYYY" v-on:change="estimateHiring"></date-picker>
          </div>
        </div>
        <div class="col-5">
          <div class="form-group mb-3">
            <label>Data Final</label>
            <br />
            <date-picker v-model="newHiringData.endDate" :first-day-of-week="1" lang="pt-br" format="DD/MM/YYYY" v-on:change="estimateHiring"></date-picker>
          </div>
        </div>

        <div class="col-2">
          <div class="form-group mb-3">
            <label for="holidaysCount">Feriados</label>
            <input id="holidaysCount" name="holidaysCount" type="tel" class="form-control" v-model="newHiringData.holidaysCount" v-on:change="estimateHiring" />
          </div>
        </div>
      </div>

      <div class="row mb-2">
        <div class="col-sm-12">
          <div class="custom-control custom-checkbox mb-2">
            <input type="checkbox" class="custom-control-input" id="includeSaturdays" v-model="newHiringData.includeSaturdays" v-on:change="estimateHiring" />
            <label class="custom-control-label" for="includeSaturdays">Incluir sábados</label>
          </div>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col">
          <label class="mb-1">Oficina</label>
          <multiselect
            v-model="newHiringData.hirer"
            :options="hirers"
            label="fullName"
            track-by="_id"
            placeholder="Pesquise pelo nome ou CNPJ"
            selectLabel=""
            selectedLabel=""
            deselectLabel=""
            :close-on-select="true"
            :searchable="true"
          ></multiselect>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col">
          <label class="mb-1">Prestador</label>
          <multiselect
            v-model="newHiringData.provider"
            :options="providers"
            label="fullName"
            track-by="_id"
            placeholder="Pesquise pelo nome ou CPF"
            selectLabel=""
            selectedLabel=""
            deselectLabel=""
            :close-on-select="true"
            :searchable="true"
            @input="changeServiceTypes"
          ></multiselect>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col col-6">
          <label class="mb-1">Serviço Contratado</label>
          <select class="form-control" v-model="newHiringData.serviceType" v-on:change="changeServiceType"> 
            <option v-for="service in serviceTypes" :key="service.serviceId" :value="service.serviceId">{{service.serviceDescription}}</option>
          </select>
        </div>

        <div class="col col-4">
          <label class="mb-1">Valor da Diária</label>
          <input class="form-control" :value="(newHiringData.service.dailyValue || 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })" readonly />
        </div>

        <div class="col col-2">
          <div class="form-group mb-1">
            <label class="mb-1">Diárias</label>
            <input id="quantity" name="quantity" type="tel" class="form-control" v-model="newHiringData.quantity" readonly />
          </div>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col col-6">
          <label class="mb-1">Valor Total</label>
          <input
            id="value"
            class="form-control"
            :value="(newHiringData.value || 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })"
            readonly
          />

          <b-btn variant="link" v-if="newHiringData.value && !newHiringData.useManualValue" class="ml-0 mr-0 pl-0" @click="changeManualValue">Alterar Valor</b-btn>
        </div>

        <div class="col col-6" v-if="newHiringData.useManualValue">
          <label class="mb-1">Valor Total</label>
          <input
            id="manualValue"
            class="form-control"
            v-model="newHiringData.manualValue"
            v-money="money"
          />

          <b-btn variant="link" class="ml-0 mr-0 pl-0" @click="changeManualValue">Usar Valor Calculado</b-btn>
        </div>
      </div>

      <div slot="modal-footer">
        <b-btn variant="link" @click="$bvModal.hide('modal-new-hiring')" class="mr-3">Cancelar</b-btn>
        <b-btn variant="primary" @click="confirmNewHiring">Criar Contratação</b-btn>
      </div>
    </b-modal>

    <b-modal
      id="modal-change-status"
      title="Alterar Status"
      title-class="font-18"
      size="sm"
      centered
    >
      <p class="m-0">Contratação: <b>{{hiringSelected.code}}</b></p>
      <p>Status Atual: <b>{{hiringSelected.statusDescription}}</b></p>

      <div class="row mb-3">
        <div class="col col-12">
          <label class="mb-1">Novo Status</label>
          <select class="form-control" v-model="newStatus"> 
            <option value="accepted">Confirmado</option>
            <option value="inProgress">Em Andamento</option>
            <option value="finish">Finalizado</option>
            <option value="canceled">Cancelado</option>
          </select>
        </div>
      </div>

      <div slot="modal-footer">
        <b-btn variant="link" @click="$bvModal.hide('modal-change-status')" class="mr-3">Cancelar</b-btn>
        <b-btn variant="primary" @click="changeHiringStatus">Alterar Status</b-btn>
      </div>
    </b-modal>
  </Layout>
</template>

<style lang="scss">
  .team {
    display: flex;
    align-items: center;    
  }

  .avatar-letter {
    height: 32px;
    width: 32px;
    min-height: 32px;
    min-width: 32px;
    background-color: rgb(241, 101, 73, 0.3);
    color: #F16549;
    line-height: 32px;
    text-align: center;
    font-weight: bold;
  }

  .search-users {
    display: flex;
    align-items: flex-end;
    margin-bottom: 8px;

    .button-large {
      flex-grow: 1;
    }
  }

  .more-filters .multiselect__tags {
    border-radius: 30px;
  }

  .form-control {
    height: 38px;
  }

  .form-control[readonly] {
    background-color: #f1f1f1;
  }

  .bottom-buttons {
    display: flex;
    justify-content: space-between;
  }
</style>